import { Box, Chip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import MyLink from "../../../common/components/MyLink";
import { some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";

interface Props {
  info: some;
  sm?: number;
}
const CallCard = (props: Props) => {
  const { info: data } = props;

  const { copyToClipboard } = useGeneralHook();

  if (!data) {
    return null;
  }

  const isOutbound = data.direction === "outbound";
  const tags =
    data.tags?.filter((tag) => !tag.name.startsWith("agent_id")) || [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: isOutbound ? "flex-end" : "flex-start",
      }}
    >
      <Box>
        <Typography variant="subtitle1">
          <FormattedMessage id="duration" />
          :&nbsp;
          <Typography variant="body1" component={"span"}>
            {data.duration}&nbsp;
            <FormattedMessage id="seconds" />
          </Typography>
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">
          <FormattedMessage id="isa" />
          :&nbsp;
          <Typography variant="body1" component={"span"}>
            {data?.number?.digits} ({data?.user.name})
          </Typography>
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">
          <FormattedMessage id="lead" />
          :&nbsp;
          <Typography variant="body1" component={"span"}>
            <MyLink href={`tel:${data?.raw_digits}`}>{data?.raw_digits}</MyLink>
          </Typography>
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">
          <FormattedMessage id="direction" />
          :&nbsp;
          <Typography variant="body1" component={"span"} className="capitalize">
            {data.direction}
          </Typography>
        </Typography>
      </Box>
      {data.missed_call_reason && (
        <Box>
          <Typography variant="subtitle1">
            <FormattedMessage id="missed_call_reason" />
            :&nbsp;
            <Typography variant="body1" component={"span"}>
              {data.missed_call_reason}&
            </Typography>
          </Typography>
        </Box>
      )}
      {data.comments?.length > 0 && (
        <Box>
          <Typography variant="subtitle1">
            <FormattedMessage id="call.comments" />
          </Typography>
          <Box className="w-fit flex flex-wrap">
            {data.comments?.map((one) => {
              return (
                <Typography key={one.id} variant="body1" component={"span"}>
                  -{one.content}&nbsp;(by {one.posted_by?.name || ""})
                </Typography>
              );
            })}
          </Box>
        </Box>
      )}
      {tags.length > 0 && (
        <Box className="w-fit flex flex-wrap">
          <Typography variant="subtitle1">
            <FormattedMessage id="tags" />
            :&nbsp;
          </Typography>
          {tags.map((tag) => {
            return (
              <Chip
                key={tag.id}
                size="small"
                color="primary"
                label={
                  <>
                    {tag.name}&nbsp;(by {tag.tagged_by?.name || ""})
                  </>
                }
              ></Chip>
            );
          })}
        </Box>
      )}
      {data.recording && (
        <Box>
          <audio src={data.recording} controls />
        </Box>
      )}
      <Typography
        variant="caption"
        color={"textSecondary"}
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: isOutbound ? "flex-end" : "flex-start",
        }}
        onDoubleClick={(e) => {
          e.stopPropagation();
          copyToClipboard(data.id, true);
        }}
      >
        {data?.started_at
          ? `${dayjs.unix(data.started_at).format("LL")} (${dayjs
              .unix(data.started_at)
              .fromNow()})`
          : "-"}
      </Typography>
    </Box>
  );
};
export default CallCard;
